<template>
  <b-input-group>
    <b-form-input
      :id="id"
      :class="`MonthPicker__input${error ? ' invalid' : ''} ${disabled ? ' disabled' : ''}`"
      :value="formatValue"
      type="text"
      :disabled="disabled || displayPicker"
      @click="toggleDisplay"
      @focus="toggleDisplay"
    />
    <b-input-group-append v-if="!disabled">
      <div class="MonthPicker">
        <div class="MonthPicker__icon">
          <i class="far fa-calendar" aria-hidden="true" @click="toggleDisplay" />
          <div v-if="displayPicker" ref="month-picker" class="MonthPicker__picker">
            <div class="MonthPicker__picker-year">
              <i
                class="fas fa-arrow-left"
                aria-hidden="true"
                :disabled="isDisabledLeftArrow"
                @click="decreaseYear"
              />
              <span>{{ yearLabel + '年' }}</span>
              <i
                class="fas fa-arrow-right"
                aria-hidden="true"
                :disabled="isDisabledRightArrow"
                @click="increaseYear"
              />
            </div>
            <div class="MonthPicker__picker-month">
              <span
                v-for="(m1, index) in month_1"
                :key="m1.text + index"
                :class="isSelected(m1.value)"
                :disabled="isDisabledMonth(m1.value)"
                @click="selectMonth(m1.value)"
                >{{ m1.text }}</span
              >
            </div>
            <div class="MonthPicker__picker-month">
              <span
                v-for="(m2, index) in month_2"
                :key="m2.text + index"
                :class="isSelected(m2.value)"
                :disabled="isDisabledMonth(m2.value)"
                @click="selectMonth(m2.value)"
                >{{ m2.text }}</span
              >
            </div>
            <div class="MonthPicker__picker-month">
              <span
                v-for="(m3, index) in month_3"
                :key="m3.text + index"
                :class="isSelected(m3.value)"
                :disabled="isDisabledMonth(m3.value)"
                @click="selectMonth(m3.value)"
                >{{ m3.text }}</span
              >
            </div>
          </div>
        </div>
      </div>
    </b-input-group-append>
  </b-input-group>
</template>
<script>
/*
 * MT Month Picker component
 * use as a input group
 *
 * */
export default {
  name: 'MTMonthPicker',
  props: {
    id: {
      type: String,
      required: true,
    },
    minDate: {
      type: Date,
      default: null,
    },
    maxDate: {
      type: Date,
      default: null,
    },
    property: {
      type: String,
      default: '',
    },
    onChange: {
      type: Function,
      default: () => {
        return {}
      },
    },
    value: {
      type: String,
      default: null,
    },
    error: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      displayPicker: false,
      selectedYear: '',
      selectedMonth: '',
      savedMonthValue: '', // parameter saves previous month value
      yearLabel: new Date().getFullYear(),
      months: [
        '1月',
        '2月',
        '3月',
        '4月',
        '5月',
        '6月',
        '7月',
        '8月',
        '9月',
        '10月',
        '11月',
        '12月',
      ],
      dataValue: this.value,
    }
  },
  computed: {
    month_1() {
      const month = this.months.slice(0, 4)
      return month.map((m, i) => {
        let val = i + 1
        val = '0' + val
        return { value: val, text: m }
      })
    },
    month_2() {
      const month = this.months.slice(4, 8)
      return month.map((m, i) => {
        let val = i + 5
        val = '0' + val
        return { value: val, text: m }
      })
    },
    month_3() {
      const month = this.months.slice(8)
      return month.map((m, i) => {
        let val = i + 9
        if (val < 10) {
          val = '0' + val
        }
        return { value: val, text: m }
      })
    },
    selectedValue() {
      if (!this.savedMonthValue) {
        return ''
      }
      let m = this.savedMonthValue
      if (Number(this.savedMonthValue) < 10) {
        m = '0' + Number(this.savedMonthValue)
      }
      return this.selectedYear + '-' + m
    },
    minMonth() {
      if (this.minDate) {
        return this.getMonthFromDate(this.minDate)
      }
      return ''
    },
    minYear() {
      if (this.minDate) {
        return this.getYearFromDate(this.minDate)
      }
      return ''
    },
    maxMonth() {
      if (this.maxDate) {
        return this.getMonthFromDate(this.maxDate)
      }
      return ''
    },
    maxYear() {
      if (this.maxDate) {
        return this.getYearFromDate(this.maxDate)
      }
      return ''
    },
    isDisabledLeftArrow() {
      if (!this.minYear) {
        return false
      }
      return this.minYear === this.yearLabel
    },
    isDisabledRightArrow() {
      if (!this.maxYear) {
        return false
      }
      return this.maxYear === this.yearLabel
    },
    formatValue() {
      if (this.selectedValue) {
        const year = this.selectedValue.split('-')[0]
        const month = Number(this.selectedValue.split('-')[1])
        return `${year}年${month}月`
      }
      return ''
    },
  },
  watch: {
    value(newVal) {
      if (newVal !== this.dataValue) {
        this.dataValue = newVal
        this.initDateValue(newVal)
      }
    },
    maxYear(val) {
      if (this.selectedYear || isNaN(val)) {
        return
      }
      this.yearLabel = val
    },
    minYear(newVal) {
      if (this.selectedYear || isNaN(newVal)) {
        return
      }
      this.yearLabel = newVal
    },
    yearLabel(val) {
      if (val !== this.selectedYear) {
        this.selectedMonth = ''
      } else {
        if (this.selectedMonth === '') {
          this.selectedMonth = this.savedMonthValue
        }
      }
    },
  },
  mounted() {
    document.addEventListener('mousedown', this.handleClickOutside)
    this.initDateValue(this.value)
  },
  methods: {
    toggleDisplay() {
      this.displayPicker = !this.displayPicker
      if (this.displayPicker) {
        document.addEventListener('mousedown', this.handleClickOutside)
      } else {
        document.removeEventListener('mousedown', this.handleClickOutside)
      }
    },
    decreaseYear() {
      this.yearLabel -= 1
    },
    increaseYear() {
      this.yearLabel += 1
    },
    selectMonth(monthValue) {
      this.selectedMonth = monthValue
      this.savedMonthValue = monthValue
      this.selectedYear = this.yearLabel
      this.onChange(this.property, this.selectedValue)
      this.toggleDisplay()
    },
    isSelected(monthValue) {
      return { selected: Number(this.selectedMonth) === Number(monthValue) }
    },
    handleClickOutside(event) {
      if (this.$refs['month-picker'] && !this.$refs['month-picker'].contains(event.target)) {
        if (this.displayPicker) {
          this.yearLabel = this.selectedYear || this.yearLabel
          this.displayPicker = false
        }
      }
    },
    getMonthFromDate(date) {
      return date.getMonth() + 1
    },
    getYearFromDate(date) {
      return date.getFullYear()
    },
    isDisabledMonth(month) {
      if (this.isDisabledLeftArrow || this.isDisabledRightArrow) {
        if (this.maxMonth && this.minMonth) {
          return Number(month) > Number(this.maxMonth) || Number(month) < Number(this.minMonth)
        }
        if (this.maxMonth) {
          return Number(month) > Number(this.maxMonth)
        }
        if (this.minMonth) {
          return Number(month) < Number(this.minMonth)
        }
      }
      return false
    },
    initDateValue(value) {
      if (value) {
        const date = new Date(Date.parse(value))
        this.selectedYear = date.getFullYear()
        this.selectedMonth = date.getMonth() + 1
        this.savedMonthValue = this.selectedMonth
        this.yearLabel = this.selectedYear
      }
    },
  },
}
</script>
