import axios from '@/configs/http/internal-api-axios'
import { extendMasterRead } from '@/utils/function-utils'

const baseUrl = 'api/v1/contracts'

async function getContract(perPage = 10, currentPage = 1, keyword = '', isMaster = false) {
  let url = `${baseUrl}?perPage=${perPage}&currentPage=${currentPage}&keyword=${keyword}`
  url = extendMasterRead(isMaster, url)
  const response = await axios.get(url).then((res) => {
    return res.data
  })
  return {
    items: response.data.items,
    total: response.data.total,
  }
}

async function createContract(formData) {
  const response = await axios
    .post(baseUrl, formData)
    .then((response) => {
      return {
        success: true,
        data: response.data,
      }
    })
    .catch((error) => {
      return {
        success: false,
        data: error.response.data,
      }
    })
  return {
    success: response.success,
    data: response.data,
  }
}

async function editContract(formData) {
  const url = `${baseUrl}/${formData.id}`
  const response = await axios
    .patch(url, formData)
    .then((res) => {
      return {
        success: true,
        data: res.data,
      }
    })
    .catch((err) => {
      return {
        success: false,
        data: err.response.data,
      }
    })
  return {
    success: response.success,
    data: response.data,
  }
}

async function deleteContract(contractId) {
  if (contractId !== '') {
    const url = `${baseUrl}/${contractId}`
    return axios.delete(url).then((res) => {
      return res.data.success
    })
  }
  return false
}

function getFields() {
  return [
    { key: 'code', label: '契約ID', sortable: true },
    { key: 'name', label: '契約プラン', sortable: true },
    { key: 'app_name', label: 'サービス名', sortable: true },
    { key: 'contractTypeName', label: '契約サイクル', sortable: true },
    { key: 'price', label: '金額', sortable: true },
    { key: 'start_date', label: '開始月', sortable: true },
    { key: 'end_date', label: '終了月', sortable: true },
    { key: 'actions', label: '' },
  ]
}

async function getsService() {
  const url = 'api/v1/dashboard/apps'
  const response = await axios.get(url).then((res) => {
    return res.data
  })
  return response.data
    .filter((item) => item.subscribe_app_status !== null && item.subscribe_app_status !== 0)
    .map((item) => {
      return {
        value: item.subscribe_app_id,
        text: item.name,
      }
    })
}

export const contractListService = {
  getFields,
  getContract,
  getsService,
  createContract,
  editContract,
  deleteContract,
}
