<template>
  <div class="CMForm">
    <b-form>
      <b-form-group id="input-group-1" label="契約プラン" label-for="input-1" class="required">
        <b-input-group>
          <b-form-input
            id="input-1"
            :value="form.name"
            type="text"
            :disabled="viewOnly"
            :class="status($v.form.name)"
            :formatter="formatter"
            @change="setFormValue('name', $event)"
          />
          <b-input-group-append v-if="!!errorMessages.form.name" class="icon-alert">
            <img src="@/assets/icons/ic_alert.svg" alt="alert-icon" />
          </b-input-group-append>
        </b-input-group>
        <span v-if="!!errorMessages.form.name" class="label-error"
          >{{ errorMessages.form.name }}
        </span>
      </b-form-group>

      <b-form-group id="select-group-1" label="サービス名" label-for="select-1" class="required">
        <b-form-select
          id="select-1"
          :value="form.subscribe_apps_id"
          class="form-select"
          size="sm"
          :class="status($v.form.subscribe_apps_id)"
          :options="appOptions"
          :disabled="editOrView"
          @change="setFormValue('subscribe_apps_id', $event)"
        />
        <span v-if="!!errorMessages.form.subscribe_apps_id" class="label-error"
          >{{ errorMessages.form.subscribe_apps_id }}
        </span>
      </b-form-group>

      <b-form-group id="select-group-2" label="契約サイクル" label-for="select-2" class="required">
        <b-form-select
          id="select-2"
          :value="form.type"
          class="form-select"
          size="sm"
          :class="status($v.form.type)"
          :options="contractTypeOptions"
          :disabled="viewOnly"
          @change="setFormValue('type', $event)"
        />
        <span v-if="!!errorMessages.form.type" class="label-error"
          >{{ errorMessages.form.type }}
        </span>
      </b-form-group>

      <b-form-group id="input-group-2" label="契約金額" label-for="input-2" class="required">
        <b-input-group>
          <b-form-input
            id="input-2"
            :value="form.price"
            type="text"
            :disabled="viewOnly"
            :class="status($v.form.price)"
            :formatter="formatterPrice"
            @change="setFormValue('price', $event)"
          />
          <b-input-group-append v-if="!!errorMessages.form.price" class="icon-alert">
            <img src="@/assets/icons/ic_alert.svg" alt="alert-icon" />
          </b-input-group-append>
        </b-input-group>
        <span v-if="!!errorMessages.form.price" class="label-error"
          >{{ errorMessages.form.price }}
        </span>
      </b-form-group>

      <b-form-group id="input-group-date" label-for="input-3">
        <div class="row">
          <div class="col-6 m-0">
            <b-form-group id="input-group-3" label="開始月" label-for="input-3" class="required">
              <month-picker
                id="input-3"
                :max-date="maxStartDate"
                :min-date="null"
                :value="form.start_date"
                :on-change="setFormValue"
                :error="!!errorMessages.form.start_date"
                :disabled="viewOnly"
                property="start_date"
              />
              <span v-if="!!errorMessages.form.start_date" class="label-error"
                >{{ errorMessages.form.start_date }}
              </span>
            </b-form-group>
          </div>
          <div class="col-6 m-0">
            <b-form-group id="input-group-4" label="終了月" label-for="input-4" class="required">
              <month-picker
                id="input-4"
                :max-date="null"
                :min-date="minEndDate"
                :value="form.end_date"
                :on-change="setFormValue"
                :error="!!errorMessages.form.end_date"
                :disabled="viewOnly"
                property="end_date"
              />
              <span v-if="!!errorMessages.form.end_date" class="label-error"
                >{{ errorMessages.form.end_date }}
              </span>
            </b-form-group>
          </div>
        </div>
      </b-form-group>
    </b-form>
  </div>
</template>

<script>
import { contractListService } from '@/services/contract-list-service'
import { FORM_MODE, MAX_LENGTH } from '@/utils/constants'
import { MESSAGES } from '@/utils/messages'
import { required } from 'vuelidate/lib/validators'
import { mapGetters } from 'vuex'
import { stringToNumberInput } from '@/utils/function-utils'
import MonthPicker from '@/components/month-picker'

export default {
  name: 'ContractListModalForm',
  components: { MonthPicker },
  props: {
    formData: {
      type: Object,
      default: null,
    },
    mode: {
      type: Number,
      default: FORM_MODE.ADD,
    },
    triggerValidate: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      form: {},
      errorMessages: {
        form: {
          name: '',
          subscribe_apps_id: '',
          type: '',
          price: '',
          start_date: '',
          end_date: '',
        },
      },
      appOptions: [],
      maxStartDate: null,
      minEndDate: null,
    }
  },
  validations: {
    form: {
      name: {
        required,
      },
      subscribe_apps_id: {
        required,
      },
      type: {
        required,
      },
      price: {
        required,
      },
      start_date: {
        required,
      },
      end_date: {
        required,
      },
    },
  },
  computed: {
    ...mapGetters('commonSettings', ['mt_contract_type']),
    contractTypeOptions() {
      return this.mt_contract_type.map((item) => {
        return {
          value: item.id,
          text: item.name,
        }
      })
    },
    defaultFormData() {
      return {
        name: '',
        subscribe_apps_id: '',
        type: '',
        price: '',
        start_date: '',
        end_date: '',
      }
    },
    viewOnly() {
      return this.mode === FORM_MODE.VIEW
    },
    editOrView() {
      return this.mode !== FORM_MODE.ADD
    },
  },
  watch: {
    form(newValue) {
      this.$emit('update:formData', newValue)
    },
    triggerValidate(newVal) {
      this.$emit('update:isValid', this.isValidForm())
    },
  },
  mounted() {
    this.form = { ...this.defaultFormData }
    if (this.formData) {
      this.form = { ...this.form, ...this.formData }
      this.form.start_date = this.formatFormDate(this.form.start_date)
      this.form.end_date = this.formatFormDate(this.form.end_date)
      this.maxStartDate = new Date(Date.parse(this.form.end_date))
      this.minEndDate = new Date(Date.parse(this.form.start_date))
    }
  },
  async created() {
    this.minEndDate = null
    this.maxStartDate = null
    this.appOptions = await contractListService.getsService()
  },
  methods: {
    setValidateErrorMessage(instance, name) {
      // check required
      if (!instance.required) {
        this.errorMessages.form[name] = MESSAGES.COMMON.MSG08
      } else {
        this.errorMessages.form[name] = ''
      }
    },
    setFormValue(name, value) {
      this.form[name] = value
      if (name === 'start_date') {
        this.minEndDate = new Date(Date.parse(value))
      }
      if (name === 'end_date') {
        this.maxStartDate = new Date(Date.parse(value))
      }
      const validateInstance = this.$v.form[name]
      validateInstance.$touch()
      this.setValidateErrorMessage(validateInstance, name)
    },
    status(validation) {
      return {
        invalid: validation.$dirty && validation.$invalid,
      }
    },
    formatter(e) {
      return String(e).substring(0, MAX_LENGTH.DEFAULT)
    },
    formatterPrice(e) {
      const value = stringToNumberInput(e)
      return String(value).substring(0, MAX_LENGTH.PRICE)
    },
    formatFormDate(value) {
      if (!value) {
        return ''
      }
      const date = new Date(Date.parse(value))
      const year = date.getFullYear()
      let month = date.getMonth() + 1
      if (month < 10) {
        month = '0' + month
      }
      return `${year}-${month}`
    },
    isValidForm() {
      this.$v.form.$touch()
      const isValid = !this.$v.form.$invalid
      if (!isValid) {
        Object.keys(this.errorMessages.form).forEach((key) => {
          this.setValidateErrorMessage(this.$v.form[key], key)
        })
      }
      return isValid
    },
  },
}
</script>
